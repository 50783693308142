<template>
  <!-- 库存管理 -->
  <div class="carRentalStockManage">
    <div class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">所属车商：</div>
        <div class="col-2">
          <el-select
            v-model="formData.carServiceProviderIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in carDealerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">取车城市：</div>
        <div class="col-2">
          <el-select
            v-model="formData.rentalCityIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in getCarCity"
              :key="item.id"
              :label="item.cityName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">取车区县：</div>
        <div class="col-2">
          <el-select
            v-model="formData.rentalDistrictIds"
            multiple
            collapse-tags
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in getCarDistrict"
              :key="item.id"
              :label="item.districtName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">取车点：</div>
        <div class="col-2">
          <el-select
            v-model="formData.pickLocationIds"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in getCarPoint"
              :key="item.id"
              :label="item.pickLocationName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">车型分类：</div>
        <div class="col-2">
          <el-select
            v-model="formData.vehicleTypeDictCodes"
            clearable
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in vehicleTypeDictList"
              :key="item.vehicleTypeDictCode"
              :label="item.vehicleTypeChildName"
              :value="item.vehicleTypeDictCode"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">车型名称：</div>
        <div class="col-2">
          <el-input v-model="formData.carModelName" clearable></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1" style="width: 100px">当前库存小于：</div>
        <div class="col-2">
          <el-input
            v-model="formData.currentCarCountLt"
            clearable
            type="number"
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryData"
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="exportData"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      operation-width="120"
      :highlight-current-row="true"
    >
      <template slot-scope="scope">
        <el-button
          v-if="listFind('修改库存上限')"
          type="text"
          size="small"
          sort="primary"
          @click="updateData(scope.scopeRow)"
          >修改库存上限</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="formData.pageSize"
        :current-page="formData.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>
    <charterPopup
      v-if="show"
      :header-text="headerText"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="库存上限：" prop="allCarCount">
          <el-input
            v-model="Form.allCarCount"
            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
          ></el-input>
        </el-form-item>
      </el-form>
    </charterPopup>
  </div>
</template>

<script>
import {
  rentalDistrictListAPI,
  rentalTableDataAPI,
  rentalCityListAPI,
  rentManageTableAPI,
  updataManageLimitAPI,
  rentIsUseAPI,
  rentManageExpAPI,
  providerListAPI,
  renderCarTypeListAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      show: false,
      formData: {
        currentPage: 1,
        pageSize: 10,
        currentCarCountLt: "",
        carModelName: "",
        carServiceProviderIds: [],
        pickLocationIds: [],
        rentalCityIds: [],
        rentalDistrictIds: [],
        vehicleTypeDictCodes: [],
      },
      carDealerList: [],
      getCarCity: [],
      getCarDistrict: [],
      getCarPoint: [],
      vehicleTypeDictList: [],
      total: 0,
      titleName: [
        {
          title: "所属车商",
          props: "carServiceProviderName",
          width: 200,
        },
        {
          title: "取车城市",
          props: "pickCityName",
          width: 120,
        },
        {
          title: "取车区县",
          props: "pickDistrictName",
        },
        {
          title: "取车点",
          props: "pickLocationName",
          width: 120,
        },
        {
          title: "车型分类",
          props: "vehicleTypeChildName",
        },
        {
          title: "车型名称",
          props: "carModelName",
        },
        {
          title: "库存上限",
          props: "allCarCount",
        },
        {
          title: "当前库存",
          props: "currentCarCount",
        },
        {
          title: "已租车辆数",
          props: "rentalCarCount",
          width: 100,
        },
        {
          title: "是否上架",
          props: "onShelf",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.onShelf === 0 ? false : true,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  rentIsUseAPI({
                    rentalVehicleModelId: params.id,
                    onShelf: params.onShelf === 0 ? 1 : 0,
                  }).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.renderData();
                    }
                  });
                },
              },
            });
          },
        },
      ],
      tableData: [],
      total: 0,
      Form: {
        allCarCount: 0,
        rentalVehicleModelId: "",
      },
      rentalCarCount: "",
      headerText: "",
      rules: {
        allCarCount: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value === "") {
                callback("请输入库存上限");
              } else {
                if (value > 300) {
                  callback("库存上限不得大于300");
                } else {
                  if (value < this.rentalCarCount) {
                    callback("库存上限不得小于租车车辆数");
                  } else {
                    callback();
                  }
                }
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    queryData() {
      this.formData.currentPage = 1;
      this.renderData();
    },
    exportData() {
      rentManageExpAPI(this.formData).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "租车库存管理" + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      rentManageTableAPI(this.formData).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    updateData(scope) {
      this.show = true;
      this.headerText = "修改";
      this.Form.allCarCount = scope.allCarCount;
      this.Form.rentalVehicleModelId = scope.id;
      this.rentalCarCount = scope.rentalCarCount;
    },
    clearForm() {
      this.show = false;
      this.Form = { allCarCount: 0, rentalVehicleModelId: "" };
    },
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          updataManageLimitAPI(this.Form).then((res) => {
            this.clearForm();
            this.renderData();
          });
        }
      });
    },
    // 分页页数变化的时候
    onCurrentChange(val) {
      this.formData.currentPage = val;
      this.renderData();
    },
    // 分页页数变化的时候
    onSizeChange(val) {
      this.formData.pageSize = val;
      this.formData.currentPage = 1;
      this.renderData();
    },
  },
  mounted() {
    this.renderData();
    rentalDistrictListAPI({ status: 0 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.getCarDistrict = res.data;
      }
    });
    rentalTableDataAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.getCarPoint = res.data;
      }
    });
    rentalCityListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.getCarCity = res.data;
      }
    });
    renderCarTypeListAPI({ businessType: 5 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.vehicleTypeDictList = res.data;
      }
    });
    providerListAPI({ openBusiness: 5 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.carDealerList = res.data;
      }
    });
  },
};
</script>

<style scoped lang="scss">
/deep/.el-input__inner {
  line-height: 20px;
}
.carRentalStockManage {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      height: 32px;
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 100px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>